/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p"
  }, _provideComponents(), props.components), {LandingPageCta} = _components;
  if (!LandingPageCta) _missingMdxReference("LandingPageCta", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, {
    className: "c-md-p"
  }, "✔ The most discreet design"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "✔ Best speech understanding"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "✔ 100% money-back guarantee"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Signia has unveiled its new Xperience hearing system — and it is revolutionizing the hearing aid industry! After 50 years of research and development, German engineers have found a way to pack cutting-edge technology in a device so small, it disappears behind or inside your ear.\nThe old hearing aids of the past were highly visible and only made sounds louder. These new hearing systems are mini computers that can pick up voices and reduce background noise automatically. The new Xperience technology uses a unique algorithm that is clinically proven to deliver better than normal hearing. This allows you to understand your family and friends clearly, even in crowded restaurants."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "We at hear.com want you to be one of the first to experience this amazing technology. Sign up here to learn more about our locations, pricing and financing options — 100% FREE of charge and without any obligations."), "\n", React.createElement(LandingPageCta, {
    copy: "Sign up for your no-risk trial",
    classes: "c-cta--content"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
